<template>
	<v-container class="flood">
		<div class="fullview" v-if="loading">
			<div class="loading-spacer"></div>
			<vue-loading v-if="loading" type="spin" color="#333" :size="{ width: '300px', height: '300px' }"></vue-loading>
		</div>
		<PlaceHeader :item="item"></PlaceHeader>
		<PlaceLocalMenu :item="item"></PlaceLocalMenu>
		<div class="main-body" style="height: 800px;">
			<ChartFloodControl :item="item"></ChartFloodControl>
		</div>
	</v-container>
</template>

<script>

import PlaceHeader from "@/components/PlaceHeader.vue";
import PlaceLocalMenu from "@/components/PlaceLocalMenu.vue";
import ChartFloodControl from "@/components/ChartFloodControl.vue";
import { VueLoading } from 'vue-loading-template'

export default {
	computed: {
	},
	components: {
		PlaceHeader
		,PlaceLocalMenu
		,ChartFloodControl
		,VueLoading
	},
	mounted() {
		
	},
	created() {
		this.item = this.$store.getters.getPlaceByIndex(this.$route.params.id);
	},
	methods: {
		
	},
	data() {
		return {
			item: null,
			loading: false
		};
	},
};
</script>

<style>
.fullview {
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.loading-spacer {
  height: 30%;
}
</style>
