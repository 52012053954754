<template>
  <div>
    <div class="block flood-input without-footer">
      <div class="header" @click="displayForm = !displayForm" style="cursor:pointer;">期間の入力</div>
      <div v-if="displayForm" class="body">
        <div class="w1">
          <div class="w2">
            開始 <b-form-datepicker 
                  id="datepicker1" 
                  v-model="date1" 
                  placeholder="未指定" 
                  class="datepicker"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @input="onInputCalender"
                >
                </b-form-datepicker>
                <b-form-timepicker 
                  v-model="time1" 
                  locale="en"
                  placeholder="未指定" 
                  @input="onInputCalender"
                >
                </b-form-timepicker>
          </div>
          <div class="w2">
            終了 <b-form-datepicker 
                  id="datepicker2" 
                  v-model="date2" 
                  placeholder="未指定" 
                  class="datepicker"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @input="onInputCalender"
                  :min="calendar_end_date_min"
                >
                </b-form-datepicker>
                <b-form-timepicker 
                  v-model="time2" 
                  locale="en"
                  placeholder="未指定" 
                  @input="onInputCalender"
                >
                </b-form-timepicker>
          </div>
        </div>
        <div class="w1">
          <b-button 
            id="calcualte" 
            @click="onClickCalculate($refs.linechart)"
            variant="primary"
            ref="calc_button"
            :disabled="!enableCalculate"
            >
            計算
          </b-button>
        </div>
      </div>
      <div v-if="!displayForm" class="footer"></div>
    </div>
    <!--
    <div class="block flood-input without-footer">
      <div class="header">計算過程</div>
      <div class="body">
        <textarea cols="1000" rows="10" v-model="log"></textarea>
      </div>
    </div>
    -->
    <div class="block">
      <div class="header" @click="displayChart = !displayChart" style="cursor:pointer;">洪水調節効果</div>
      <div v-if="displayChart" class="body" >
        <LineChartGenerator
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
        ref="linechart"
      />
        <!-- <div class="overlay"></div> -->
      </div>
      <div class="footer">
        <div></div>
        <div v-if="displayChart">
          <button @click="onClickZoomOut($refs.linechart)">-</button>
          <button @click="onClickZoomIn($refs.linechart)">+</button>
          <button @click="onClickResetZoom($refs.linechart)">リセット</button>
        </div>
      </div>
    </div>
    <div v-if="!displayChart" class="footer"></div>
</div>
</template>
  
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
// import annotationPlugin from 'chartjs-plugin-annotation'; // アノテーション描画で必要
import zoomPlugin from 'chartjs-plugin-zoom';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  TimeScale,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler
  ,zoomPlugin
)

// Bootstrap-Vue references : 
// https://bootstrap-vue.org/docs/components/form-input
// https://bootstrap-vue.org/docs/components/form-datepicker


export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  }
  ,
  async created() {
    this.$parent.loading = true;
    // 日付の設定
    let pastDate = new Date();
		pastDate.setHours(pastDate.getHours() - 4);
    this.date1 = pastDate.getFullYear() + "-" + (pastDate.getMonth() + 1) + "-" + pastDate.getDate();
    this.time1 = pastDate.getHours() + ":" + pastDate.getMinutes();
    console.log(this.date1);
    let nowDate = new Date();
    this.date2 = nowDate.getFullYear() + "-" + (nowDate.getMonth() + 1) + "-" + nowDate.getDate();
    this.time2 = nowDate.getHours() + ":" + nowDate.getMinutes();
    let d = this.parseDate();
    // データの取得
    let index = this.$store.getters.getPlaceIndexById(this.item.id);
    let waterlevelData = await this.$store.dispatch("getWaterlevelData", { index: index, start: d.start, end: d.end });
    let rainData = await this.$store.dispatch("getRainData", { index: index, start: d.start, end: d.end });
    this.waterlevel = waterlevelData.level.concat();
    this.waterlevelTs = waterlevelData.ts.concat();
    this.rain = rainData.level.concat();
    this.rainTs = rainData.ts.concat();
		this.fulllevel = this.item.fulllevel;
		let data = this.setFloodControlData();
    this.setChartData(data);
    this.$parent.loading = false;
	}
  ,
  mounted() {
    // console.log("mounted") 
	}
  ,
  props: {
    chartId: {
      type: String,
      default: 'chart-water-history'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Array,
    //   default: () => []
    // }
    // ,
    item: {
      type: Object,
      default: () => []
    }
  },
  data() { 
    return {
      chart:null
      ,chartData: {}
      ,chartOptions: {}
      ,displayForm: false
      ,displayChart: true
      ,input_peak: 1.0
      ,output_peak: 1.2
      ,rain_peak: 5.0
      ,legend_width: 170
      ,date1: null
      ,time1: null
      ,date2: null
      ,time2: null
      ,enableCalculate : false
      ,calendar_end_date_min : null
      ,fulllevel: null
      ,waterlevel: null
      ,waterlevelTs: null
      ,rain: null
      ,rainTs: null
      ,log: ""
      ,
        plugins: [
          {
            // 特殊なレジェンドの作成
            id: 'custom_legends',

            beforeDraw: (function (chart) {
              
              try{

                const w = this.legend_width
                const h = 70
                const scales = chart.scales
                const chartArea = chart.chartArea
                const p = this.$store.state.floodColors
                // const c = this.$store.getters.getColors

                let y = 0
                let ctx = chart.ctx;

                const xpos_0 = chartArea.left + chartArea.width 
                const xpos_1 = chart.width - w

                //雨量
                ctx.fillStyle = p.rain.color;
                ctx.fillRect(chart.width - w, y, w/2, 30 );
                ctx.fillStyle = p.rain.text_color;
                ctx.font = "bold 14px sans-serif";
                ctx.textAlign = "left";
                ctx.fillText( p.rain.name, chart.width  - w * 0.45, y+18);

                y += 40

                //流域流入量(ピーク時)
                let ypos = scales.water_level.getPixelForValue( this.input_peak )

                ctx.setLineDash([5,5]);
                ctx.strokeStyle = p.input.color;
                ctx.strokeRect(chart.width - w, y, w - 1, h);
                
                ctx.fillStyle = p.input.text_color;
                ctx.font = "bold 16px sans-serif";
                ctx.textAlign = "center";
                ctx.fillText( p.input.name, chart.width  - w/2, y+22);
                ctx.font = "bold 20px sans-serif";
                ctx.fillText( this.input_peak.toFixed(2) + '(㎥/s)' , chart.width  - w/2, y+55);
                
                
                ctx.beginPath () ;
                ctx.strokeStyle = p.input.color;
                ctx.lineWidth = 1 ;
                ctx.moveTo( chartArea.left, ypos ) ;
                ctx.lineTo( xpos_0, ypos ) ;
                ctx.stroke() ;

                ctx.beginPath () ;
                ctx.strokeStyle = p.input.color;
                ctx.lineWidth = 2 ;
                
                ctx.moveTo( xpos_0, ypos ) ;
                ctx.lineTo( xpos_1, y + h/2 )
                
                ctx.stroke() ;
                
                y += h + 10

                //下流放流量(ピーク時)
                ypos = scales.water_level.getPixelForValue( this.output_peak )

                ctx.setLineDash([5,5]);
                ctx.strokeStyle = p.output.color;
                ctx.strokeRect(chart.width - w, y, w - 1, h);
                
                ctx.fillStyle = p.output.text_color;
                ctx.font = "bold 16px sans-serif";
                ctx.textAlign = "center";
                ctx.fillText( p.output.name, chart.width  - w/2, y+22);
                ctx.font = "bold 20px sans-serif";
                ctx.fillText( this.output_peak.toFixed(2) + '(㎥/s)' , chart.width  - w/2, y+55);

                ctx.beginPath () ;
                ctx.strokeStyle = p.output.color;
                ctx.lineWidth = 1 ;
                ctx.moveTo( chartArea.left, ypos ) ;
                ctx.lineTo( xpos_0, ypos ) ;
                ctx.stroke() ;
                ctx.beginPath () ;
                ctx.strokeStyle = p.output.color;
                ctx.lineWidth = 2 ;
                ctx.moveTo( xpos_0, ypos ) ;
                ctx.lineTo( xpos_1, y + h/2 )
                ctx.stroke() ;
                y += h + 10
                //ため池による最大抑制量
                ctx.fillStyle = p.supression.color;
                ctx.fillRect(chart.width - w, y, w - 1, h);
                ctx.fillStyle = p.supression.text_color;
                ctx.font = "bold 14px sans-serif";
                ctx.textAlign = "center";
                ctx.fillText( p.supression.name, chart.width  - w/2, y+22);
                ctx.font = "bold 20px sans-serif";
                ctx.fillText( (this.input_peak - this.output_peak).toFixed(2) + '(㎥/s)' , chart.width  - w/2, y+55);

                ctx.beginPath () ;
                ctx.strokeStyle = p.supression.color;
                ctx.lineWidth = 1 ;
                ctx.moveTo( chartArea.left, ypos ) ;
                ctx.lineTo( xpos_0, ypos ) ;
                ctx.stroke() ;
                // 単位
                ctx.textAlign = 'left';
                ctx.fillStyle = "#555";
                ctx.font = "normal 14px sans-serif";
                ctx.fillText('雨量(mm/10min)', 10, 14 );
                ctx.restore();
                // 単位
                ctx.textAlign = 'right';
                ctx.fillStyle = "#555";
                ctx.font = "normal 14px sans-serif";
                ctx.fillText('流量(㎥/s)', chart.width - w - 15, 14 );
                ctx.restore();

              } catch( e){
                  console.log(e)
              }
            }).bind(this)
          }
        ]
    }
  }
  ,methods : {
    parseDate() {
      // 日付のパース
      const t1 = this.time1.split(':')
      let pd1 = new Date(this.date1)
      const d1 = new Date(pd1.getFullYear(), pd1.getMonth(), pd1.getDate(), t1[0], t1[1]);
      const t2 = this.time2.split(':')
      let pd2 = new Date(this.date2)
      const d2 = new Date(pd2.getFullYear(), pd2.getMonth(), pd2.getDate(), t2[0], t2[1]);
      return {"start": d1, "end": d2};
    },
    onClickRange(e,r){
      console.log(e,r)
    }
    ,
    onClickZoomIn(e){
      let chart = e.getCurrentChart()
      if(chart.getZoomLevel() < 10){
        chart.zoom( 1.2 , 'x')
      }
    }
    ,
    onClickZoomOut(e){
      let chart = e.getCurrentChart()
      chart.zoom( 0.8 , 'x')
    }
    ,
    onClickResetZoom(e){
      let chart = e.getCurrentChart()
      chart.resetZoom()
    }
    ,
    async onClickCalculate(){
      // データの取得
      this.$parent.loading = true;
      let d = this.parseDate();
      let waterlevelData = await this.$store.dispatch("getWaterlevelData", { index: this.item.id, start: d.start, end: d.end });
      let rainData = await this.$store.dispatch("getRainData", { index: this.item.id, start: d.start, end: d.end });
      this.waterlevel = waterlevelData.level.concat();
      this.waterlevelTs = waterlevelData.ts.concat();
      this.rain = rainData.level.concat();
      this.rainTs = rainData.ts.concat();
      this.fulllevel = this.item.fulllevel;
      let data = this.setFloodControlData();
      this.setChartData(data);
      this.$parent.loading = false;
    }
    ,onInputCalender(){
      if(!this.date1 || !this.time1 || !this.date2 || !this.time2){
        this.enableCalculate = false
      } else {
        this.enableCalculate = true
      }
      if(this.date1 ){
        this.calendar_end_date_min = new Date( this.date1 )
      }
    }
    ,
    searchNearestRainData(dt) {
      let minTsIndex = 0;
      let minTsVal = Number.MAX_VALUE;
      for(let i = 1; i < this.rainTs.length; i++) {
        let ts = new Date(this.rainTs[i]);
        // 日時の差をミリ秒単位で取得して絶対値を比較
        if (Math.abs(dt.getTime() - ts.getTime()) < minTsVal) {
          minTsIndex = i;
          minTsVal = Math.abs(dt.getTime() - ts.getTime());
        }
      }
      // console.log("Result: " + dt + " / " + new Date(this.rainTs[minTsIndex]));
      return this.rain[minTsIndex];
    },
    setFloodControlData() {
      this.log = "";
      let data = {
        labels:[] // 日時
        ,input: [] // 流域流入量
        ,output:[] // 下流放流量
        ,rain: [] // 雨量
      };
      let ryuryoArray = [], ryuryoArray2 = [], ryuryoArray3 = [], ryuryoArray4 = [];
      if (!this.waterlevel) return;
      this.rain_peak = Number.MIN_VALUE;
      this.input_peak = Number.MIN_VALUE;
      this.output_peak = Number.MIN_VALUE;
      let delta_t, delta_v, v_current, past_v = 0;
      for (let i = 0; i < this.waterlevel.length; i++) {
        if (typeof this.waterlevelTs[i] == "string") {
          data.labels.push(new Date(this.waterlevelTs[i]));
        } else {
          data.labels.push(this.waterlevelTs[i]);
        }
        if (i == 0) {
          let h = this.fulllevel + this.waterlevel[i];
          past_v = (this.item.c * h + this.item.pondBottomArea * 2) * h * 0.5;
          data.input.push(null);
          data.output.push(null);
          data.rain.push(null);
          continue;
        }
        let rain = this.searchNearestRainData(data.labels[i]);
        delta_t = (data.labels[i] - data.labels[i-1]) / 1000.0;
        // ここから貯水量の計算
        let h = this.fulllevel + this.waterlevel[i];
        v_current = (this.item.c * h + this.item.pondBottomArea * 2) * h * 0.5;
        delta_v = v_current - past_v;
        past_v = v_current;
        // ここから降雨量の計算
        let r = rain / 1000.0;
        // 降雨流入量
        let rr = r * this.item.topEdgeArea;
        // ここから洪水吐の計算
        // 洪水吐流出の有無
        let kozuibakiFlag = true;
        if (this.waterlevel[i] <= 0) kozuibakiFlag = false;
        // 越流深
        let etsuryushin = this.waterlevel[i];
        if (!kozuibakiFlag) etsuryushin = 0;
        // 水路幅
        let suirohaba = this.item.kozuibakiHaba;
        // スリット流出の有無
        let slitFlag = true;
        if (h <= this.item.slitMansuii) slitFlag = false;
        if (slitFlag) suirohaba = this.item.kozuibakiHabaWithoutSlit;
        // 流量 (㎡)
        let ryuryo = this.item.ryuryoKeisu * suirohaba * (Math.pow(etsuryushin, 1.5));
        // 流量（㎥）
        let ryuryo_delta = ryuryo;
        ryuryoArray.push(ryuryo_delta);
        let slitEtsuryushin = 0;
        if (slitFlag) slitEtsuryushin = h - this.item.slitMansuii;
        let ryuryo2 = this.item.slitRyuryoKeisu * this.item.slitHaba * (Math.pow(slitEtsuryushin, 1.5));
        let ryuryo2_delta = ryuryo2;
        ryuryoArray2.push(ryuryo2_delta);
        // ここから放流口（自由水面あり）の計算
        // 池底から流入口下端までの深度(m)
        let pond_bottom_depth = this.fulllevel - (this.item.shusuiKoKanjoTanshin + this.item.shusuiKoKanChokkei);
        let kanroTekiyo = false;
        if (h > pond_bottom_depth) {
          if (h < (pond_bottom_depth + this.item.shusuiKoKanChokkei)) kanroTekiyo = true;
        }
        // 天端から流入口上端までの深度(m)
        let tentan_depth = this.item.shusuiKoKanjoTanshin + this.item.kozuibakiSuishin;
        let suishin = 0;
        if (kanroTekiyo) suishin = h - pond_bottom_depth;
        let theta = 0;
        if (kanroTekiyo) theta = 2.0 * Math.acos(1.0 - (2.0 * suishin / this.item.shusuiKoKanChokkei));
        let tsusuiDanmenseki = 0;
        if (kanroTekiyo) tsusuiDanmenseki = Math.pow(this.item.shusuiKoKanChokkei, 2.0) * (theta - Math.sin(theta)) / 8.0;
        let keishin = 0;
        if (kanroTekiyo) keishin = this.item.shusuiKoKanChokkei * (1.0 - Math.sin(theta) / theta) / 4.0;
        // 流量 (省略)
        let ryuryo3, ryuryo3_delta = 0;
        if (kanroTekiyo) ryuryo3 = this.item.shusuiKoSodoKeisu * Math.pow(keishin, 2.0/3.0) * Math.pow(this.item.shusuiKoKanroKobai, 0.5) / this.item.shusuiKoSodoKeisu;
        if (kanroTekiyo) ryuryo3_delta = ryuryo3;
        ryuryoArray3.push(ryuryo3_delta);
        // ここから放流口 (満水時)の計算
        let orificeTekiyo = false;
        if (h >= (pond_bottom_depth + this.item.shusuiKoKanChokkei)) orificeTekiyo = true;
        let danmenseki = 3.14 * Math.pow((this.item.shusuiKoKanChokkei / 2.0), 2.0);
        // 池底から孔中心までの深度(m)
        let pond_bottom_central_depth = pond_bottom_depth + this.item.shusuiKoKanChokkei / 2.0;
        // 水面から中心までの水深(m)
        let surface_to_central_depth = 0;
        if (orificeTekiyo) surface_to_central_depth = h - pond_bottom_central_depth;
        let ryuryo4 = 0;
        if (orificeTekiyo) ryuryo4 = this.item.shusuiKoRyuryoKeisu * danmenseki * (Math.pow(2.0 * 9.8 * surface_to_central_depth, 0.5));
        let ryuryo4_delta = 0;
        if (orificeTekiyo) ryuryo4_delta = ryuryo4;
        ryuryoArray4.push(ryuryo4_delta);
        // 下流放流量
        let output = ryuryo_delta + ryuryo2_delta + ryuryo3_delta + ryuryo4_delta;
        let input = (delta_v - rr + ryuryo4_delta) / delta_t;
        data.input.push(input);
        data.output.push(output);
        data.rain.push(rain);
        if (input > this.input_peak) this.input_peak = input;
        if (output > this.output_peak) this.output_peak = output;
        if (rain > this.rain_peak) this.rain_peak = rain;
			}
			this.log += "水位: " + this.logStr(this.waterlevel) + "\n";
			this.log += "雨量: " + this.logStr(data.rain) + "\n";
			this.log += "洪水吐流量: " + this.logStr(ryuryoArray) + "\n";
			this.log += "スリット流量: " + this.logStr(ryuryoArray2) + "\n";
			this.log += "放流口 (自由水面あり)流量: " + this.logStr(ryuryoArray3) + "\n";
			this.log += "放流口 (満流時)流量: " + this.logStr(ryuryoArray4) + "\n";
			this.log += "流域流入量: " + this.logStr(data.input) + "\n";
			this.log += "下流放流量: " + this.logStr(data.output) + "\n";
      return data;
    }
    ,
    setChartData(data) {
      this.chartData = {
        labels: data.labels
        ,
        datasets: [
          {
            label: '流域流入量'
            ,fill : false
            ,borderColor: "#3e95cd"
            ,borderWidth: 2
            ,pointRadius: 2
            ,data: data.input
            ,yAxisID: 'water_level'
          }
          ,
          {
            label: '下流放流量'
            ,fill : false
            ,borderColor: "#FF0DB4"
            ,borderWidth: 2
            ,pointRadius: 2
            ,data: data.output
            ,yAxisID: 'water_level'
          }
          ,
          {
            label: '雨量'
            ,fill : true
            ,borderColor: "#B7DFFF"
            ,backgroundColor: 'rgba(183,223,255,0.7)'
            ,borderWidth: 1
            ,pointRadius: 2
            ,data: data.rain
            ,yAxisID: 'rain'
          }
        ]
      };
      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false
        ,
        layout: {
            padding: {
              right: 170 + 10
              ,top: 30
            }
        }
        ,
        scales: {
          x: {
            ticks: {
              callback: function(val) { // , index
                // let num_ticks = this.ticks.length
                let s = ''
                let ds = new Date( this.getLabelForValue(val) )
                  s = (ds.getMonth() + 1) + '/' + ds.getDate() + ' ' + ('0' + ds.getHours()).slice(-2) + ':' + ('0' + ds.getMinutes()).slice(-2); 
                  // s = ds.getMonth() + 1 + '/' + ds.getDate() + ' ' + ds.getHours() + ':' + ds.getMinutes()
                return s;
              },
              color: 'black',
            }
          }
          ,
          rain: {
            type: 'linear',
            display: true,
            position: 'left'
            ,grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            }
            ,ticks: {
                stepSize: 0.2
            }
            ,reverse: true
            ,max: (function(){ return Math.max(...[this.rain_peak * 2.2].concat([1.0]))}).bind(this)
            ,min: (function(){ return Math.min(...this.rain.concat([0]))}).bind(this)
            // , max : this.$store.state.floodControlData[this.item.id].peaks.rain * 2
          },
          water_level: {
            type: 'linear',
            display: true,
            position: 'right'
            ,min: (function(){ return Math.max(this.input_peak, this.output_peak) - Math.abs(Math.max(this.input_peak, this.output_peak))}).bind(this)
            ,max: (function(){ return Math.max(this.input_peak, this.output_peak) + Math.abs(Math.max(this.input_peak, this.output_peak)) * 1.2}).bind(this)
            // ,max : this.$store.state.floodControlData[this.item.id].peaks.input * 2
          },
        }
        ,plugins: {
          annotation: { // This is needed to stop error "RangeError: Maximum call stack size exceeded"
              annotations: [

              ]
          },
            tooltip: {
                callbacks: {
                  title: function(context){
                    let title = new Date(context[0].label);
                    return title.getFullYear() + '/' + (title.getMonth()+1) + '/'  + title.getDate() + ' ' + ('0' + title.getHours()).slice(-2) + ':' + ('0' + title.getMinutes()).slice(-2)
                  }
                  ,
                  label: function(context) {
                      let label = context.dataset.label || '';

                      if (label) {
                          label += ' : ';
                      }
                      if (context.parsed.y !== null) {
                          if (context.dataset.label == "雨量") {
                            label += new Intl.NumberFormat('en-US', { style: 'decimal', maxDigits: 2 }).format(context.parsed.y) + ' mm/10min';
                          } else {
                            label += new Intl.NumberFormat('en-US', { style: 'decimal', maxDigits: 2 }).format(context.parsed.y) + ' ㎥/s';
                          }
                      }
                      return label;
                  }
                }
            }
            ,
            legend: {
              display: false
              ,position: 'right'
            }
            ,
            zoom:{
              limits:{
                // x : { min: 20, max: 100 }
              }
              ,
              zoom : {
                wheel : {
                  enabled: true
                  ,speed : 0.01
                  
                }
                ,mode : 'x'
              }
              ,pan : {
                  enabled: true
                  ,mode : 'x'
                }
            }
        }
      }
    },
    logStr: function(array) {
			let str = "[ ";
			for (var i = 0; i < array.length; i++) {
				let val = Math.round(array[i] * 100.0) / 100.0;
				if (i == array.length-1) str += val;
				else str += val + ", ";
			}
			return str + " ]";
    }
  }
}
</script>
<style lang="scss">
// .overlay { position: absolute; top:0; left:0; width: 100%; height: 100%; display: none; }
// body.can-zoom .overlay { display:none; }

.flood-input{
  .body { 
    display: flex; align-items: center; justify-content: center; font-weight: bold; font-size: 18px; 
    .w1{
      margin: 1em 1em;  
      .w2 {
        display: flex; align-items: center; justify-content: center;
        &:first-child {  margin-bottom: 1em; }
      }
    } 
    
    .b-form-datepicker { width: 150px; margin: 0 1em; }
    .b-form-timepicker { width: 150px; }
  }
}


</style>